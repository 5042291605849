<template>
  <div v-loading="loading">
    <div class="container padd10">
      <div class="x-c-end" style="width: 100%">
        <el-button type="primary" size="mini" @click="save">保存</el-button>
        <el-button size="mini" @click="grantAll">授予所有权限</el-button>
        <el-button size="mini" @click="cancelAll">取消所有权限</el-button>
        <el-button @click="getQuit" size="mini">退出</el-button>
      </div>
      <el-form class="marT10" v-model="form" :rules="rules" :inline="false" label-width="120px">
        <cardTitleCom cardTitle="授权人">
          <template #cardContent>
            <div class="padd10">
              <el-form-item label="被授予人">
                <el-input size="mini" v-model="form.userName" style="width: 220px">
                  <i slot="suffix" class="el-icon-more" @click="selectGoods"></i>
                </el-input>
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="价格限制">
          <template #cardContent>
            <div class="padd10">
              <el-row>
                <el-col :span="2">
                  <div class="column">
                    <div class="fontS14" style="width: 130px; text-align: right">
                      折扣/折让权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50" style="width: 120px" v-model="
                        form.pricePermissionsReq.isAllowedDiscountCheckAll
                      " @change="priceCheckAllFn1">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.pricePermissionsReq.isAllowedSingleDiscount">单品折扣
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="column x-f">
                    <div class="fontS14" style="width: 220px; text-align: right">
                      可对单商品打最高折扣（折）
                    </div>
                    <el-input style="width: 160px" v-model="form.pricePermissionsReq.singleGoodsDiscount" size="mini"
                      placeholder="请输入折扣0-100%"></el-input>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="column x-c">
                    <div class="grid-content bg-purple-light"></div>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px"></div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <div class="marL50 width120"></div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.pricePermissionsReq.isAllowedWholeOrderDiscount
                      ">整单折扣
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="column x-f">
                    <div class="fontS14" style="width: 220px; text-align: right">
                      可对整单打最高折扣（折）
                    </div>
                    <el-input style="width: 160px" v-model="form.pricePermissionsReq.wholeOrderDiscount" size="mini"
                      placeholder="请输入折扣0-100%"></el-input>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="column x-c">
                    <div class="grid-content bg-purple-light"></div>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px"></div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <div class="marL50 width120"></div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.pricePermissionsReq.isAllowedWholeOrderAllowances
                      ">整单议价
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="column x-f">
                    <div class="fontS14" style="width: 220px; text-align: right">
                      最大优惠金额（元）
                    </div>
                    <el-input style="width: 160px" v-model="form.pricePermissionsReq.wholeOrderAllowances" size="mini"
                      placeholder="请输入金额"></el-input>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="column x-c">
                    <div class="grid-content bg-purple-light"></div>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      退货权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50" style="width: 120px"
                      v-model="form.pricePermissionsReq.returnAuthorityCheckAll" @change="priceCheckAllFn2">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.pricePermissionsReq.isAllowedReturn">退货
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="column x-f">
                    <div class="fontS14" style="width: 220px; text-align: right">
                      最高退货退款金额（元）
                    </div>
                    <el-input style="width: 160px" v-model="
                        form.pricePermissionsReq.maximumReturnRefundAmount
                      " size="mini" placeholder="请输入金额"></el-input>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="column x-f">
                    <div class="fontS14 x-c" style="width: 100px">
                      最多能退最近
                    </div>
                    <el-input class="width80" v-model="form.pricePermissionsReq.allowRefundNearestDay" size="mini"
                      placeholder="请输入"></el-input>
                    <div class="fontS14 x-c marL5" style="width: 160px">
                      天的零售单（仅销售）
                    </div>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="column x-c width180">
                    <el-checkbox v-model="
                        form.pricePermissionsReq.isAllowedCancelNoSourceOrder
                      ">无票退货权限
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      赠送权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50 width120" v-model="form.pricePermissionsReq.giftPermissionCheckAll"
                      @change="priceCheckAllFn3">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.pricePermissionsReq.isAllowedQuickGifts">快速赠品
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="column x-f">
                    <div class="fontS14" style="width: 220px; text-align: right">
                      赠品售价限额（元）
                    </div>
                    <el-input style="width: 160px" v-model="form.pricePermissionsReq.giftPriceLimit" size="mini"
                      placeholder="请输入金额"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      <!-- 改价/抹零权限 -->
                      改价权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50 width120" v-model="
                        form.pricePermissionsReq
                          .priceChangeErasePermissionsCheckAll
                      " @change="priceCheckAllFn4">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <!--                               <el-col :span="3">-->
                <!--                                   <div class="column x-c">-->
                <!--                                       <el-checkbox class="width80" v-model="form.pricePermissionsReq.isAllowedErasePermissions"-->
                <!--                                       >抹零-->
                <!--                                       </el-checkbox>-->
                <!--                                   </div>-->
                <!--                               </el-col>-->
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.pricePermissionsReq.isAllowedPriceChangePermissions
                      ">改价
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="功能权限">
          <template #cardContent>
            <div class="padd10">
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      交接班权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50" style="width: 120px" v-model="
                        form.functionPermissionsReq
                          .isAllowedChangeShiftsCheckAll
                      " @change="priceCheckAllFn12">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq
                          .isAllowedChangeShiftsReconciliation
                      ">交款对账</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowedGoodsReconciliation
                      ">商品对账</el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      会员权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50" style="width: 120px" v-model="
                        form.functionPermissionsReq
                          .isAllowedVipAuthorityCheckAll
                      " @change="priceCheckAllFn6">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowedAddVip">新增会员
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowedEditVip">编辑会员
                    </el-checkbox>
                  </div>
                </el-col>
                <!--                               <el-col :span="3">-->
                <!--                                   <div class="column x-c">-->
                <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowCashierModifyVipPassword"-->
                <!--                                       >收银员修改会员密码-->
                <!--                                       </el-checkbox>-->
                <!--                                   </div>-->
                <!--                               </el-col>-->
                <!--                               <el-col :span="3">-->
                <!--                                   <div class="column x-c">-->
                <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowModifyVipPassword"-->
                <!--                                       >修改会员密码-->
                <!--                                       </el-checkbox>-->
                <!--                                   </div>-->
                <!--                               </el-col>-->
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowModifyVipTelephone
                      ">变更会员手机号
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowDeltaNegative">会员卡充负值
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px"></div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120 marL50" v-model="
                        form.functionPermissionsReq.isAllowNoCardQueryVipInfo
                      ">无卡查询会员
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowGrantVipCard">允许发放会员卡
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50 width120" v-model="
                        form.functionPermissionsReq.isAllowModifyGiftAmount
                      ">修改赠送金额
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120" v-model="
                        form.functionPermissionsReq.isAllowModifyAmountAuthority
                      ">允许修改充值金额
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowReplaceCard">会员卡换卡
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowModifyCardStatus
                      ">会员卡状态修改
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      收银员常规操作
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50 width120" v-model="
                        form.functionPermissionsReq
                          .isAllowedNormalOperationCheckAll
                      " @change="priceCheckAllFn7">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowCashier">允许收银
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowOpenCashBox">开启钱箱
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowAddGoods">新增商品
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <!--                           <el-row class="marT10">-->
              <!--                               <el-col :span="2">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <div class="fontS14" style="width: 150px;height: 30px;text-align: right">价格权限-->
              <!--                                       </div>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="marL50 width120"-->
              <!--                                                    v-model="form.functionPermissionsReq.isAllowedPriceAuthorityCheckAll" @change="priceCheckAllFn8"-->
              <!--                                       >全选-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowWholeOrderDiscount"-->
              <!--                                       >整单折扣权限-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowWholeOrderDiscount"-->
              <!--                                       >单品折扣权限-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowModifySingleGoodsPrice"-->
              <!--                                       >单品改价-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowModifyWholeOrderPrice"-->
              <!--                                       >整单改价-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowGift"-->
              <!--                                       >允许赠送-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                           </el-row>-->
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      单据权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50 width120" v-model="
                        form.functionPermissionsReq
                          .isAllowedBillAuthorityCheckAll
                      " @change="priceCheckAllFn9">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowFrontRefund">前台退货
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowSupplementTicket
                      ">补打小票
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq
                          .isAllowRegistrationAndRetrievalOrders
                      ">挂单/取单
                    </el-checkbox>
                  </div>
                </el-col>
                <!--                               <el-col :span="3">-->
                <!--                                   <div class="column x-c">-->
                <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowCancelOrder"-->
                <!--                                       >撤销单据-->
                <!--                                       </el-checkbox>-->
                <!--                                   </div>-->
                <!--                               </el-col>-->
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowDelRegistrationOrder
                      ">删除挂单单据
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowCancellation">取消预订
                    </el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      数据查看权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50 width120" v-model="
                        form.functionPermissionsReq
                          .isAllowedDataPermissionsCheckAll
                      " @change="priceCheckAllFn5">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowedLocalShopStock
                      ">查看本店库存
                    </el-checkbox>
                  </div>
                </el-col>
                <!-- <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox
                      class="width80"
                      v-model="
                        form.functionPermissionsReq.isAllowedAllShopStock
                      "
                    >
                      查看所有门店库存
                    </el-checkbox>
                  </div>
                </el-col> -->
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.functionPermissionsReq.isAllowedAllStoreStock
                      ">查看全部仓库库存
                    </el-checkbox>
                  </div>
                </el-col>
                <!--                               <el-col :span="3">-->
                <!--                                   <div class="column x-c">-->
                <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowedShiftHandoverAmount"-->
                <!--                                       >交班显示收款金额-->
                <!--                                       </el-checkbox>-->
                <!--                                   </div>-->
                <!--                               </el-col>-->
              </el-row>
              <!--                           <el-row class="marT10">-->
              <!--                               <el-col :span="2">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <div class="fontS14" style="width: 150px;height: 30px;"></div>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width120 marL50" v-model="form.functionPermissionsReq.isAllowEmptyRetrievalOrders"-->
              <!--                                       >清空挂单-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                           </el-row>-->
              <!--                           <el-row class="marT10">-->
              <!--                               <el-col :span="2">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <div class="fontS14" style="width: 150px;height: 30px;text-align: right">库存权限-->
              <!--                                       </div>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="marL50 width120"-->
              <!--                                                    v-model="form.functionPermissionsReq.isAllowedStockAuthorityCheckAll" @change="priceCheckAllFn10"-->
              <!--                                       >全选-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowAuthorizeStock"-->
              <!--                                       >盘点权限-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowShowGoodsStock"-->
              <!--                                       >明盘（显示当前库存数）-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3" class="marL50">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowModifyGoodsStock"-->
              <!--                                       >修改商品库存-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                           </el-row>-->
              <!--                           <el-row class="marT10">-->
              <!--                               <el-col :span="2">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <div class="fontS14" style="width: 150px;height: 30px;text-align: right">查询权限-->
              <!--                                       </div>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="marL50 width120"-->
              <!--                                                    v-model="form.functionPermissionsReq.isAllowedQueryAuthorityCheckAll" @change="priceCheckAllFn11"-->
              <!--                                       >全选-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                               <el-col :span="3">-->
              <!--                                   <div class="column x-c">-->
              <!--                                       <el-checkbox class="width80" v-model="form.functionPermissionsReq.isAllowShellAndStockReport"-->
              <!--                                       >销售和库存报表查询权限-->
              <!--                                       </el-checkbox>-->
              <!--                                   </div>-->
              <!--                               </el-col>-->
              <!--                           </el-row>-->
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="报表查询权限">
          <template #cardContent>
            <div class="padd10">
              <el-row class="marT10">
                <el-col :span="2">
                  <div class="column x-c">
                    <div class="fontS14" style="width: 150px; height: 30px; text-align: right">
                      报表查看权限
                    </div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="marL50" style="width: 120px" v-model="
                        form.reportPermissionsReq.isAllowedReportFormsCheckAll
                      " @change="priceCheckAllFn13">全选
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120 marL50"
                      v-model="form.reportPermissionsReq.isAllowedSalesIcon">销售图表</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120 marL50"
                      v-model="form.reportPermissionsReq.isAllowedSalesSummary">销售汇总</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width80" v-model="
                        form.reportPermissionsReq
                          .isAllowedHandoverReconciliation
                      ">交班对账记录
                    </el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120 marL50" v-model="
                        form.reportPermissionsReq.isAllowedCashierReceipt
                      ">收银小票查询</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120 marL50" v-model="
                        form.reportPermissionsReq.isAllowedPaymentMethodsSummary
                      ">收款方式汇总</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox class="width120 marL50" v-model="
                        form.reportPermissionsReq.isAllowedVipRechargeQuery
                      ">会员充值查询</el-checkbox>
                  </div>
                </el-col>
                <!-- <el-col :span="3">
                  <div class="column x-c">
                    <el-checkbox
                      class="width120 marL50"
                      v-model="form.reportPermissionsReq.isAllowedCashierDaily"
                      >收银员日报
                    </el-checkbox>
                  </div>
                </el-col> -->
              </el-row>
            </div>
          </template>
        </cardTitleCom>
      </el-form>
    </div>
    <cashier-dialog :showDia="showDia" @getSelectData="getSelectData"></cashier-dialog>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom'
import cashierDialog from '@/views/shop/components/cashierDialog.vue'
import { permissions, savePermissions } from '@/api/shop/base/shopCashier'
export default {
  // 授予权限
  name: 'staffJurisdiction',
  components: {
    cardTitleCom,
    cashierDialog
  },
  data() {
    return {
      loading: false,
      showDia: false, // 选择收银员弹框
      form: {
        cid: '', // 被授权人ID
        userName: '',
        type: 1, // 权限类型
        pricePermissionsReq: {
          allowRefundNearestDay: 0,
          giftPermissionCheckAll: false, // 赠送权限 全选
          giftPriceLimit: 0,
          isAllowedCancelNoSourceOrder: false,
          isAllowedDiscountCheckAll: false, // 折扣/折让 全选
          isAllowedErasePermissions: false,
          isAllowedPriceChangePermissions: false,
          isAllowedQuickGifts: false,
          isAllowedReturn: false,
          returnAuthorityCheckAll: false, // 退货权限 全选
          isAllowedSingleDiscount: false,
          isAllowedWholeOrderAllowances: false,
          isAllowedWholeOrderDiscount: false,
          maximumReturnRefundAmount: 0,
          priceChangeErasePermissionsCheckAll: false, // 改价/抹零权限 全选
          singleGoodsDiscount: 0,
          wholeOrderAllowances: 0,
          wholeOrderDiscount: 0
        },
        functionPermissionsReq: {
          isAllowAddGoods: false,
          isAllowAuthorizeStock: false,
          isAllowCancelOrder: false,
          isAllowCancellation: false,
          isAllowCashier: false,
          isAllowCashierModifyVipPassword: false,
          isAllowDelRegistrationOrder: false,
          isAllowDeltaNegative: false,
          isAllowEmptyRetrievalOrders: false,
          isAllowFrontRefund: false,
          isAllowGift: false,
          isAllowGrantVipCard: false,
          isAllowModifyCardStatus: false,
          isAllowModifyGiftAmount: false,
          isAllowModifyAmountAuthority: false,
          isAllowModifyGoodsStock: false,
          isAllowModifySingleGoodsPrice: false,
          isAllowModifyVipPassword: false,
          isAllowModifyVipTelephone: false,
          isAllowModifyWholeOrderPrice: false,
          isAllowNoCardQueryVipInfo: false,
          isAllowOpenCashBox: false,
          isAllowRegistrationAndRetrievalOrders: false,
          isAllowReplaceCard: false,
          isAllowShellAndStockReport: false,
          isAllowShowGoodsStock: false,
          isAllowSingleGoodsDiscount: false,
          isAllowSupplementTicket: false,
          isAllowWholeOrderDiscount: false,
          isAllowedAddVip: false,
          isAllowedAllShopStock: false,
          isAllowedAllStoreStock: false,
          isAllowedDataPermissionsCheckAll: false, // 数据查看权限 全选
          isAllowedEditVip: false,
          isAllowedLocalShopStock: false,
          isAllowedShiftHandoverAmount: false,
          isAllowedViewHistoryRecords: false,
          isAllowedVipAuthorityCheckAll: false, // 会员权限 全选
          isAllowedNormalOperationCheckAll: false, // 收银员常规操作 全选
          // isAllowedPriceAuthorityCheckAll: false, // 价格权限 全选
          isAllowedBillAuthorityCheckAll: false, // 单据权限 全选
          // isAllowedStockAuthorityCheckAll: false, // 库存权限 全选
          isAllowedQueryAuthorityCheckAll: false, // 查询权限 全选
          isAllowedChangeShiftsCheckAll: false,
          isAllowedChangeShiftsReconciliation: false,
          isAllowedGoodsReconciliation: false
        },
        reportPermissionsReq: {
          isAllowedReportFormsCheckAll: false,
          isAllowedCashierDaily: false,
          isAllowedSalesIcon: false,
          isAllowedSalesSummary: false,
          isAllowedHandoverReconciliation: false,
          isAllowedCashierReceipt: false,
          isAllowedPaymentMethodsSummary: false,
          isAllowedVipRechargeQuery: false
        }
      },
      rules: {
        userName: [
          { required: true, message: '请选择收银员', trigger: ['change'] }
        ]
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //根据商品id 获取商品详情
      // let userInfo = to.query.selectData
      console.log('userInfo权限：', to.query.cid)
      vm.form.cid = to.query.cid
      vm.form.userName = to.query.userName
      if (vm.form.cid != undefined) {
        vm.getPermissions()
      }
    })
  },
  methods: {
    //授予所有权限
    grantAll() {
      this.form = {
        functionPermissionsReq: {
          isAllowAddGoods: true,
          isAllowAuthorizeStock: true,
          isAllowCancelOrder: true,
          isAllowCancellation: true,
          isAllowCashier: true,
          isAllowCashierModifyVipPassword: true,
          isAllowDelRegistrationOrder: true,
          isAllowDeltaNegative: true,
          isAllowEmptyRetrievalOrders: true,
          isAllowFrontRefund: true,
          isAllowGift: true,
          isAllowGrantVipCard: true,
          isAllowModifyCardStatus: true,
          isAllowModifyGiftAmount: true,
          isAllowModifyAmountAuthority: true,
          isAllowModifyGoodsStock: true,
          isAllowModifySingleGoodsPrice: true,
          isAllowModifyVipPassword: true,
          isAllowModifyVipTelephone: true,
          isAllowModifyWholeOrderPrice: true,
          isAllowNoCardQueryVipInfo: true,
          isAllowOpenCashBox: true,
          isAllowRegistrationAndRetrievalOrders: true,
          isAllowReplaceCard: true,
          isAllowShellAndStockReport: true,
          isAllowShowGoodsStock: true,
          isAllowSingleGoodsDiscount: true,
          isAllowSupplementTicket: true,
          isAllowWholeOrderDiscount: true,
          isAllowedAddVip: true,
          isAllowedAllShopStock: true,
          isAllowedAllStoreStock: true,
          isAllowedDataPermissionsCheckAll: true, // 数据查看权限 全选
          isAllowedEditVip: true,
          isAllowedLocalShopStock: true,
          isAllowedShiftHandoverAmount: true,
          isAllowedViewHistoryRecords: true,
          isAllowedVipAuthorityCheckAll: true, // 会员权限 全选
          isAllowedNormalOperationCheckAll: true, // 收银员常规操作 全选
          isAllowedPriceAuthorityCheckAll: true, // 价格权限 全选
          isAllowedBillAuthorityCheckAll: true, // 单据权限 全选
          isAllowedStockAuthorityCheckAll: true, // 库存权限 全选
          isAllowedQueryAuthorityCheckAll: true, // 查询权限 全选
          isAllowedChangeShiftsCheckAll: true,
          isAllowedChangeShiftsReconciliation: true,
          isAllowedGoodsReconciliation: true
        },
        pricePermissionsReq: {
          ...this.form.pricePermissionsReq,
          //   allowRefundNearestDay: 0,
          giftPermissionCheckAll: true, // 赠送权限 全选
          //   giftPriceLimit: 0,
          isAllowedCancelNoSourceOrder: true,
          isAllowedDiscountCheckAll: true, // 折扣/折让 全选
          isAllowedErasePermissions: true,
          isAllowedPriceChangePermissions: true,
          isAllowedQuickGifts: true,
          isAllowedReturn: true,
          returnAuthorityCheckAll: true, // 退货权限 全选
          isAllowedSingleDiscount: true,
          isAllowedWholeOrderAllowances: true,
          isAllowedWholeOrderDiscount: true,
          //   maximumReturnRefundAmount: 0,
          priceChangeErasePermissionsCheckAll: true // 改价/抹零权限 全选
          //   singleGoodsDiscount: 0,
          //   wholeOrderAllowances: 0,
          //   wholeOrderDiscount: 0
        },
        reportPermissionsReq: {
          isAllowedReportFormsCheckAll: true,
          isAllowedCashierDaily: true,
          isAllowedSalesIcon: true,
          isAllowedSalesSummary: true,
          isAllowedHandoverReconciliation: true,
          isAllowedCashierReceipt: true,
          isAllowedPaymentMethodsSummary: true,
          isAllowedVipRechargeQuery: true
        },
        type: 1, // 权限类型
        userName: this.form.userName,
        cid: this.form.cid
      }
    },
    //取消所有权限
    cancelAll() {
      this.reset(this.form?.cid, this.form.userName)
    },
    // 表单重置
    reset(cid, userName) {
      this.form = {
        functionPermissionsReq: {
          isAllowAddGoods: false,
          isAllowAuthorizeStock: false,
          isAllowCancelOrder: false,
          isAllowCancellation: false,
          isAllowCashier: false,
          isAllowCashierModifyVipPassword: false,
          isAllowDelRegistrationOrder: false,
          isAllowDeltaNegative: false,
          isAllowEmptyRetrievalOrders: false,
          isAllowFrontRefund: false,
          isAllowGift: false,
          isAllowGrantVipCard: false,
          isAllowModifyCardStatus: false,
          isAllowModifyGiftAmount: false,
          isAllowModifyAmountAuthority: false,
          isAllowModifyGoodsStock: false,
          isAllowModifySingleGoodsPrice: false,
          isAllowModifyVipPassword: false,
          isAllowModifyVipTelephone: false,
          isAllowModifyWholeOrderPrice: false,
          isAllowNoCardQueryVipInfo: false,
          isAllowOpenCashBox: false,
          isAllowRegistrationAndRetrievalOrders: false,
          isAllowReplaceCard: false,
          isAllowShellAndStockReport: false,
          isAllowShowGoodsStock: false,
          isAllowSingleGoodsDiscount: false,
          isAllowSupplementTicket: false,
          isAllowWholeOrderDiscount: false,
          isAllowedAddVip: false,
          isAllowedAllShopStock: false,
          isAllowedAllStoreStock: false,
          isAllowedDataPermissionsCheckAll: false, // 数据查看权限 全选
          isAllowedEditVip: false,
          isAllowedLocalShopStock: false,
          isAllowedShiftHandoverAmount: false,
          isAllowedViewHistoryRecords: false,
          isAllowedVipAuthorityCheckAll: false, // 会员权限 全选
          isAllowedNormalOperationCheckAll: false, // 收银员常规操作 全选
          isAllowedPriceAuthorityCheckAll: false, // 价格权限 全选
          isAllowedBillAuthorityCheckAll: false, // 单据权限 全选
          isAllowedStockAuthorityCheckAll: false, // 库存权限 全选
          isAllowedQueryAuthorityCheckAll: false, // 查询权限 全选
          isAllowedChangeShiftsCheckAll: false,
          isAllowedChangeShiftsReconciliation: false,
          isAllowedGoodsReconciliation: false
        },
        pricePermissionsReq: {
          allowRefundNearestDay: 0,
          giftPermissionCheckAll: false, // 赠送权限 全选
          giftPriceLimit: 0,
          isAllowedCancelNoSourceOrder: false,
          isAllowedDiscountCheckAll: false, // 折扣/折让 全选
          isAllowedErasePermissions: false,
          isAllowedPriceChangePermissions: false,
          isAllowedQuickGifts: false,
          isAllowedReturn: false,
          returnAuthorityCheckAll: false, // 退货权限 全选
          isAllowedSingleDiscount: false,
          isAllowedWholeOrderAllowances: false,
          isAllowedWholeOrderDiscount: false,
          maximumReturnRefundAmount: 0,
          priceChangeErasePermissionsCheckAll: false, // 改价/抹零权限 全选
          singleGoodsDiscount: 0,
          wholeOrderAllowances: 0,
          wholeOrderDiscount: 0
        },
        reportPermissionsReq: {
          isAllowedReportFormsCheckAll: false,
          isAllowedCashierDaily: false,
          isAllowedSalesIcon: false,
          isAllowedSalesSummary: false,
          isAllowedHandoverReconciliation: false,
          isAllowedCashierReceipt: false,
          isAllowedPaymentMethodsSummary: false,
          isAllowedVipRechargeQuery: false
        },
        type: 1, // 权限类型
        cid,
        userName
      }
    },
    //去收银员
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$router.push('/shop/base/cashier')
    },
    getPermissions() {
      this.loading = true
      permissions({
        cid: this.form.cid,
        type: 1
      })
        .then(res => {
          if (res.data?.permissions) {
            this.form = JSON.parse(res.data?.permissions)
          } else {
            this.reset(this.form.cid, this.form.userName)
          }
          this.loading = false
          console.log('form：', this.form)
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 选择被授权人
    selectGoods() {
      console.log('显示')
      this.showDia = true
    },
    // 折扣权限全选
    priceAstrictkAllChange(e) {
      console.log('---', e)
    },
    // 弹框选择收银员
    getSelectData(data) {
      this.form.cid = data[0].cashierId
      this.form.userName = data[0].cashierName
      console.log('编辑权限', typeof this.form.cid)
      this.getPermissions()
      this.showDia = false
    },
    // 保存
    save() {
      if (!this.form.cid) return this.$message.error('收银员不能为空')
      savePermissions(this.form).then(res => {
        this.$message.success('保存成功')
      })
    },
    // --------------------- 全选 事件--------------------------
    priceCheckAllFn1(val) {
      console.log('全选1=', val)
      this.form.pricePermissionsReq.isAllowedSingleDiscount = val
      this.form.pricePermissionsReq.isAllowedWholeOrderDiscount = val
      this.form.pricePermissionsReq.isAllowedWholeOrderAllowances = val
    },
    priceCheckAllFn2(val) {
      console.log('全选=', val)
      this.form.pricePermissionsReq.isAllowedReturn = val
      this.form.pricePermissionsReq.isAllowedCancelNoSourceOrder = val
    },
    priceCheckAllFn3(val) {
      console.log('全选=', val)
      this.form.pricePermissionsReq.isAllowedQuickGifts = val
    },
    priceCheckAllFn4(val) {
      console.log('全选=', val)
      // this.form.pricePermissionsReq.isAllowedErasePermissions = val
      this.form.pricePermissionsReq.isAllowedPriceChangePermissions = val
    },
    priceCheckAllFn5(val) {
      console.log('全选=', val)
      this.form.functionPermissionsReq.isAllowedLocalShopStock = val
      // this.form.functionPermissionsReq.isAllowedAllShopStock = val
      this.form.functionPermissionsReq.isAllowedAllStoreStock = val
      // this.form.functionPermissionsReq.isAllowedShiftHandoverAmount = val
      this.form.functionPermissionsReq.isAllowedViewHistoryRecords = val
    },
    priceCheckAllFn6(val) {
      console.log('全选=', val)
      this.form.functionPermissionsReq.isAllowedAddVip = val
      this.form.functionPermissionsReq.isAllowedEditVip = val
      // this.form.functionPermissionsReq.isAllowCashierModifyVipPassword = val
      // this.form.functionPermissionsReq.isAllowModifyVipPassword = val
      this.form.functionPermissionsReq.isAllowModifyVipTelephone = val
      this.form.functionPermissionsReq.isAllowDeltaNegative = val
      this.form.functionPermissionsReq.isAllowNoCardQueryVipInfo = val
      this.form.functionPermissionsReq.isAllowGrantVipCard = val
      this.form.functionPermissionsReq.isAllowModifyGiftAmount = val
      this.form.functionPermissionsReq.isAllowModifyAmountAuthority = val
      this.form.functionPermissionsReq.isAllowReplaceCard = val
      this.form.functionPermissionsReq.isAllowModifyCardStatus = val
    },
    priceCheckAllFn7(val) {
      console.log('全选=', val)
      this.form.functionPermissionsReq.isAllowCashier = val
      this.form.functionPermissionsReq.isAllowOpenCashBox = val
      this.form.functionPermissionsReq.isAllowAddGoods = val
    },
    priceCheckAllFn8(val) {
      console.log('全选=', val)
      // this.form.functionPermissionsReq.isAllowWholeOrderDiscount = val
      // this.form.functionPermissionsReq.isAllowWholeOrderDiscount = val
      // this.form.functionPermissionsReq.isAllowModifySingleGoodsPrice = val
      // this.form.functionPermissionsReq.isAllowModifyWholeOrderPrice = val
      // this.form.functionPermissionsReq.isAllowGift = val
    },
    priceCheckAllFn9(val) {
      console.log('全选=', val)
      this.form.functionPermissionsReq.isAllowFrontRefund = val
      this.form.functionPermissionsReq.isAllowSupplementTicket = val
      // this.form.functionPermissionsReq.isAllowCancelOrder = val
      this.form.functionPermissionsReq.isAllowRegistrationAndRetrievalOrders =
        val
      this.form.functionPermissionsReq.isAllowDelRegistrationOrder = val
      this.form.functionPermissionsReq.isAllowCancellation = val
      // this.form.functionPermissionsReq.isAllowEmptyRetrievalOrders = val
    },
    priceCheckAllFn10(val) {
      console.log('全选=', val)
      // this.form.functionPermissionsReq.isAllowAuthorizeStock = val
      // this.form.functionPermissionsReq.isAllowShowGoodsStock = val
      // this.form.functionPermissionsReq.isAllowModifyGoodsStock = val
    },
    priceCheckAllFn11(val) {
      console.log('全选=', val)
      // this.form.functionPermissionsReq.isAllowShellAndStockReport = val
    },
    priceCheckAllFn12(val) {
      this.form.functionPermissionsReq.isAllowedChangeShiftsReconciliation = val
      this.form.functionPermissionsReq.isAllowedGoodsReconciliation = val
    },
    priceCheckAllFn13(val) {
      this.form.reportPermissionsReq.isAllowedSalesIcon = val
      this.form.reportPermissionsReq.isAllowedSalesSummary = val
      this.form.reportPermissionsReq.isAllowedHandoverReconciliation = val
      this.form.reportPermissionsReq.isAllowedCashierReceipt = val
      this.form.reportPermissionsReq.isAllowedPaymentMethodsSummary = val
      this.form.reportPermissionsReq.isAllowedVipRechargeQuery = val
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #e9e9e9;
  width: 100%;

  .column {
    height: 30px;
    width: 100%;
  }

  .width80 {
    width: 80px;
  }

  .width120 {
    width: 120px;
  }

  .width180 {
    width: 180px;
  }
}
</style>
